let flowId = '';

function create_UUID()
{
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

function isValidUUID(uuid)
{
    if (!uuid) {
        return false;
    }

    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(uuid);
}

const PROBABLY_CORRECT_COUNTRY = {
    'af' : 'ZA',
    'am' : 'ET',
    'ar' : 'AE',
    'as' : 'IN',
    'az' : 'AZ',
    'ba' : 'RU',
    'be' : 'BY',
    'bg' : 'BG',
    'bn' : 'BD',
    'bo' : 'CN',
    'br' : 'FR',
    'bs' : 'BA',
    'ca' : 'ES',
    'co' : 'FR',
    'cs' : 'CZ',
    'cy' : 'GB',
    'da' : 'DK',
    'de' : 'DE',
    'ds' : 'DE',
    'dv' : 'MV',
    'el' : 'GR',
    'en' : 'US',
    'es' : 'ES',
    'et' : 'EE',
    'eu' : 'ES',
    'fa' : 'IR',
    'fi' : 'FI',
    'fo' : 'FO',
    'fr' : 'FR',
    'fy' : 'NL',
    'ga' : 'IE',
    'gd' : 'GB',
    'gl' : 'ES',
    'gs' : 'FR',
    'gu' : 'IN',
    'ha' : 'NG',
    'he' : 'IL',
    'hi' : 'IN',
    'hr' : 'HR',
    'hs' : 'DE',
    'hu' : 'HU',
    'hy' : 'AM',
    'id' : 'ID',
    'ig' : 'NG',
    'ii' : 'CN',
    'it' : 'IT',
    'iu' : 'CA',
    'ja' : 'JP',
    'ka' : 'GE',
    'kk' : 'KZ',
    'kl' : 'GL',
    'km' : 'KH',
    'kn' : 'IN',
    'ko' : 'KR',
    'ky' : 'KG',
    'lb' : 'LU',
    'lo' : 'LA',
    'lt' : 'LT',
    'lv' : 'LV',
    'mi' : 'NZ',
    'mk' : 'MK',
    'ml' : 'IN',
    'mn' : 'MN',
    'mo' : 'CA',
    'mr' : 'IN',
    'ms' : 'BN',
    'mt' : 'MT',
    'nb' : 'NO',
    'ne' : 'NP',
    'nl' : 'NL',
    'nn' : 'NO',
    'ns' : 'ZA',
    'oc' : 'FR',
    'or' : 'IN',
    'pa' : 'IN',
    'pl' : 'PL',
    'pr' : 'AF',
    'ps' : 'AF',
    'pt' : 'PT',
    'qu' : 'GT',
    'rm' : 'CH',
    'ro' : 'RO',
    'ru' : 'RU',
    'rw' : 'RW',
    'sa' : 'RU',
    'se' : 'SE',
    'si' : 'LK',
    'sk' : 'SK',
    'sl' : 'SI',
    'sm' : 'SE',
    'sq' : 'AL',
    'sr' : 'RS',
    'sv' : 'FI',
    'sw' : 'KE',
    'sy' : 'SY',
    'ta' : 'IN',
    'te' : 'IN',
    'tg' : 'TJ',
    'th' : 'TH',
    'tk' : 'TM',
    'tn' : 'ZA',
    'tr' : 'TR',
    'tt' : 'RU',
    'tz' : 'DZ',
    'ug' : 'CN',
    'uk' : 'UA',
    'ur' : 'PK',
    'uz' : 'UZ',
    'vi' : 'VN',
    'wo' : 'SN',
    'xh' : 'ZA',
    'yo' : 'NG',
    'zh' : 'CN',
    'zu' : 'ZA'
};

function determineLanguage()
{
    const language = navigator.languages[0];
    return language.split('-')[0];
}

function determineCountry()
{
    const language = navigator.languages[0];
    if (language.split('-').length > 1) {
        return language.split('-')[1];
    }
    const map = new Map(Object.entries(PROBABLY_CORRECT_COUNTRY));
    if (map.get(language.split('-')[0])) {
        return map.get(language.split('-')[0]);
    }
    return 'US';
}

function moveStorageSessionUuidToFormOrCreate()
{
    // @codingStandardsIgnoreStart
    // it's assumed that DOM tree is built before the heyflow-screen-view event is fired
    let shadowRoot = (
            document.querySelector(`[flow-id="${flowId}"]`) &&
            document.querySelector(`[flow-id="${flowId}"]`).shadowRoot
        ) ||
        document;
    // @codingStandardsIgnoreEnd

    let sessionIdForm = shadowRoot.querySelector('[data-variable="session_uuid"]');

    if (!sessionIdForm) {
        console.warn('No "session_uuid" form element found! Unable to save session_uuid to form data.');

        return;
    }

    sessionId = sessionStorage.getItem('session_uuid');
    sessionStorage.removeItem('session_uuid');

    if (isValidUUID(sessionId)) {
        sessionIdForm.value = sessionId;

        return;
    }

    sessionId = sessionIdForm.value;

    if (isValidUUID(sessionId)) {
        return;
    }

    sessionId = create_UUID();
    sessionIdForm.value = sessionId;
}

function moveTestnameAndTestvariantToForm()
{
    // @codingStandardsIgnoreStart
    let shadowRoot = (
            document.querySelector(`[flow-id="${flowId}"]`) &&
            document.querySelector(`[flow-id="${flowId}"]`).shadowRoot
        ) ||
        document;
    // @codingStandardsIgnoreEnd

    let testname1Form = shadowRoot.querySelector('[data-variable="testname1"]');
    let testvariant1Form = shadowRoot.querySelector('[data-variable="testvariant1"]');
    let testname2Form = shadowRoot.querySelector('[data-variable="testname2"]');
    let testvariant2Form = shadowRoot.querySelector('[data-variable="testvariant2"]');

    if (!testname1Form || !testvariant1Form || !testname2Form || !testvariant2Form) {
        console.warn('Form element(s) for AB tests not found! Unable to save AB tests to form data.');

        return {};
    }

    let testname1 = sessionStorage.getItem('testname1');
    let testvariant1 = sessionStorage.getItem('testvariant1');

    if (testname1 && testvariant1) {
        sessionStorage.removeItem('testname1');
        sessionStorage.removeItem('testvariant1');

        testname1Form.value = testname1;
        testvariant1Form.value = testvariant1;
    }

    testname1 = testname1Form.value;
    testvariant1 = testvariant1Form.value;

    let testname2 = testname2Form.value;
    let testvariant2 = testvariant2Form.value;

    let abTests = {};
    if (testname1 && testvariant1) {
        abTests[testname1] = testvariant1;
    }
    if (testname2 && testvariant2) {
        abTests[testname2] = testvariant2;
    }

    return abTests;
}

window.dataLayer = window.dataLayer || [];
function gtag()
{
    dataLayer.push(arguments);
}

function grantGtagConsent()
{
    gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'personalization_storage': 'granted',
        'functionality_storage': 'granted',
        'security_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
    });
}

gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'personalization_storage': 'denied',
    'functionality_storage': 'denied',
    'security_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'wait_for_update': 500,
});

const params = new URLSearchParams(window.location.search);
let sessionId = params.get('session_uuid');
let testname1 = params.get('testname1');
let testvariant1 = params.get('testvariant1');
let reloadWithoutHiddenParameters = false;

if (sessionId) {
    reloadWithoutHiddenParameters = true;

    if (isValidUUID(sessionId)) {
        sessionStorage.setItem('session_uuid', sessionId);
    }

    params.delete('session_uuid');
}

if (testname1 && testvariant1) {
    reloadWithoutHiddenParameters = true;

    sessionStorage.setItem('testname1', testname1);
    sessionStorage.setItem('testvariant1', testvariant1);

    params.delete('testname1');
    params.delete('testvariant1');
}

if (reloadWithoutHiddenParameters) {
    let query = params.toString();
    //phpcs:ignore
    query = query ? `?${query}` : '';
    window.location = `${window.location.protocol}//${window.location.host}${window.location.pathname}${query}`;
}

window.addEventListener('heyflow-init', (event) => {
    console.debug(event.detail);
    flowId = event.detail.flowID;

    console.debug('heyflow-init');
});

window.addEventListener('heyflow-screen-view', (event) => {
    moveStorageSessionUuidToFormOrCreate();
    let abTests = moveTestnameAndTestvariantToForm();

    const customProperties = {
        heyflow_id: flowId,
        channel: params.get('utm_source'),
        mediasource: params.get('utm_medium'),
        campaignid: params.get('utm_campaign'),
        adsetid: params.get('utm_term'),
        adid: params.get('utm_content')
    }

    const header = {
        userId: null,
        backendToken: null,
        age: null,
        platform: 'heyflow',
        platformVersion: null,
        appVersion: null,
        deviceManufacturer: null,
        deviceModel: null,
        language: determineLanguage(),
        country: determineCountry(),
        sex: null,
        overallGoal: null,
        loginMethod: null,
        activeThirdPartyGateway: null,
        weightGoal: null,
        weightCurrent: null,
        bodyMassIndex: null,
        subscriptionStatus: null,
        subscriptionSKU: null,
        hasWaterTracker: null,
        hasPodcast: null,
        hasNotes: null,
        emailAddressConfirmed: null,
        deviceNotificationOptIn: null,
        newsletterOptIn: null,
        activeFastingTracker: null,
        activeMealPlan: null,
        recommendationCount: null,
        ratePromptShown: null,
        buddyCount: null,
        userUUID: null,
        abTests: abTests,
    }

    const data = [{
        header: header,
        events: [
            {
                type: 'Impression',
                name: 'heyflow.' + window.location.hash.replace('#', ''),
                date: {
                    isoDateTime: new Date()
                },
                sessionId: sessionId,
                impressionType: 'ScreenView',
                properties: JSON.stringify(customProperties)
            //phpcs:ignore
            }
        ],
    }];

    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/vnd.yazio.events+json',
    });

    const url = `https://event-ingestion.yazio-analytics.com`;

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors',
        referrer: 'https://pro-signup.yazio.com',
        timeout: 10000,
    };
    console.debug(options);
    fetch(url, options)
        .then(response => {
            console.log(response.status);
            console.debug(options);
            return response.json();
        }).then(data => console.log(data))
        .catch(error => {
            console.log(error.status);
            console.log(error.message);
        });
    console.debug('heyflow-screen-view');
});

window.addEventListener('heyflow-button-click', (event) => {
    grantGtagConsent();
    console.debug('heyflow-button-click');
});

window.addEventListener('heyflow-input-click', (event) => {
    grantGtagConsent();
    console.debug('heyflow-input-click');
});
